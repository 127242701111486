import * as React from 'react'

import {
  formatDistanceToNow,
  getContentRoutePathname,
  Routes,
  useFormattableI18nProperty,
  useSiteDefinition,
} from '@thg-commerce/enterprise-core'
import { AsgardPost } from '@thg-commerce/enterprise-network/types/enterpriseTypes'
import { PostCard } from '@thg-commerce/enterprise-widget-post-cards'
import { ArticlePreviewDirection } from '@thg-commerce/gravity-elements'

import { buildImageSrc } from '../../../utils'

export const InfinitePostRenderer = ({
  item,
  isAmp,
  hideSeparator,
  direction,
  margin,
  alternativeImageStyle,
}: {
  item: AsgardPost
  isAmp: boolean
  hideSeparator?: boolean
  direction?: ArticlePreviewDirection | ArticlePreviewDirection[]
  margin?: string | string[]
  alternativeImageStyle?: boolean
}) => {
  const {
    contentRoutePrefix,
    contentSlug,
    imageServiceURL,
  } = useSiteDefinition()

  const placeholderImage = `data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='1'%3E%3Crect width='1' height='1' fill='%23f2f2f2' /%3E%3C/svg%3E`
  const authorText = useFormattableI18nProperty('content.authorprefix.text')

  const articleProps = {
    title: item.title,
    i18nAuthorText: authorText(item.authorName),
    category:
      item.categoryLabel.charAt(0).toUpperCase() + item.categoryLabel.slice(1),
    description: item.excerpt,
    articleAge: formatDistanceToNow(new Date(item.date), {
      addSuffix: true,
    }),
    image: {
      isAmp,
      src: [
        {
          url: item?.imageSource?.['data-featured-image-scaled']
            ? buildImageSrc(
                item.imageSource?.['data-featured-image-scaled'],
                500,
                imageServiceURL,
              )
            : placeholderImage,
        },
      ],
      alt: '',
    },
    articleUrl: getContentRoutePathname(
      contentRoutePrefix,
      item.titleLink.replace(`${contentSlug}/`, ''),
    ),
    categoryUrl: getContentRoutePathname(
      contentRoutePrefix,
      item.typeLink.replace(`${contentSlug}/`, ''),
    ),
    authorUrl: getContentRoutePathname(
      contentRoutePrefix,
      Routes.BlogAuthor.replace(':slug', item.author),
    ),
  }

  return (
    <PostCard
      article={articleProps}
      data-testid="post-card"
      key={item.id}
      hideSeparator={hideSeparator}
      direction={direction}
      margin={margin}
      alternativeImageStyle={alternativeImageStyle}
    />
  )
}
